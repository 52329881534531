import { HttpStatusCode } from '@solidjs/start';
import { createAsync, useAction, useParams, useSearchParams } from '@solidjs/router';
import {
	Button,
	DialogContent,
	Dialog,
	Errors,
	FieldDescription,
	Form,
	Label,
	Link,
	Radio,
	RadioBar,
	RadioBarButton,
	RadioGroup,
	DialogTrigger,
	TextLink,
} from '@troon/ui';
import { For, Show, createSignal, useContext, Suspense, Switch, Match, createUniqueId } from 'solid-js';
import { IconCalendar, IconFlag, IconGolfCart, IconMapPin, IconSquareWarning } from '@troon/icons';
import { Meta, Title } from '@solidjs/meta';
import { createStore, produce } from 'solid-js/store';
import { gql, mutationAction, useMutation } from '../../../../../graphql';
import { dayTimeToDate, formatDateTime } from '../../../../../modules/date-formatting';
import { FacilityCtx } from '../../../../../providers/facility';
import { Content } from '../../../../../components/content';
import { FacilityHeader } from '../../../../../components/facility/header';
import { Grid, GridMain, GridSidebar } from '../../../../../components/layouts/grid';
import { PaymentInfo } from '../../../../../components/payment-info';
import { cardBrandToComponent, cardBrandToString } from '../../../../../modules/credit-cards';
import { AddCard } from '../../../../../partials/add-card';
import { useUser } from '../../../../../providers/user';
import { AuthFlow } from '../../../../../partials/auth/auth';
import { GenericLayout } from '../../../../../layouts/generic';
import { cachedQuery } from '../../../../../graphql/cached-get';
import { createNumberFormatter, holesFormatter } from '../../../../../modules/number-formatting';
import { useUtmParams } from '../../../../../providers/utm';
import { ErrorBoundary } from '../../../../../components/error-boundary';
import ConfirmSubscription from '../../../../../components/confirm-subscription';
import { AccessCardUpsellRate } from './components/access-upsell-rate';
import type { ComponentProps } from 'solid-js';
import type { CalendarDayTime, CourseTeeTimeRate, CreditCard } from '../../../../../graphql';

export default function ReserveTeeTimeWrapper() {
	const facility = useContext(FacilityCtx);
	const [searchParams] = useSearchParams<{
		subscriptionId?: string;
		productId?: string;
	}>();
	const [confirmSubscriptionOpen, setConfirmSubscriptionOpen] = createSignal(
		!!(searchParams.subscriptionId && searchParams.productId),
	);

	return (
		<GenericLayout>
			<Content>
				<Show when={facility()?.facility}>
					{(facility) => <Title>{`Book tee times | ${facility()?.name} | Troon`}</Title>}
				</Show>
				<Meta name="robots" content="noindex" />

				<FacilityHeader
					// @ts-expect-error TODO: need to fix fragment masking
					facility={facility()?.facility ?? {}}
					showHero
				/>

				<h1 class="sr-only">Book your tee time</h1>

				<div class="mb-6 mt-2 border-b border-neutral-500 sm:hidden" />

				<ErrorBoundary
					content={
						<div class="flex flex-col gap-6">
							<h2 class="flex flex-row items-center gap-2 text-3xl font-semibold text-red-500">
								<IconSquareWarning />
								An error has occured
							</h2>

							<p>This tee time is no longer available.</p>
							<div class="flex flex-row">
								<div class="flex flex-row gap-4">
									<Button
										as={Link}
										href={`/course/${facility()?.facility.slug}/reserve-tee-time`}
										onClick={(e) => {
											e.preventDefault();
											history.back();
										}}
									>
										Go back
									</Button>
								</div>
							</div>
						</div>
					}
				>
					<ReserveTeeTime />
				</ErrorBoundary>

				<Show when={searchParams.subscriptionId && searchParams.productId}>
					<Dialog
						key="subscription-confirmation"
						defaultOpen
						open={confirmSubscriptionOpen()}
						onOpenChange={setConfirmSubscriptionOpen}
					>
						<DialogContent>
							<ConfirmSubscription
								subscriptionId={searchParams.subscriptionId!}
								productId={searchParams.productId!}
								onContinue={() => {
									setConfirmSubscriptionOpen(false);
								}}
								continueText="Continue booking"
							/>
						</DialogContent>
					</Dialog>
				</Show>
			</Content>
		</GenericLayout>
	);
}

function ReserveTeeTime() {
	const params = useParams<{ facilityId: string; teeTimeId: string }>();
	const facility = useContext(FacilityCtx);
	const [searchParams] = useSearchParams<{
		rateId?: string;
		players?: string;
		triggerId?: string;
		includeAccess?: string;
		subscriptionId?: string;
		productId?: string;
	}>();
	const utm = useUtmParams();
	const numberFormatter = createNumberFormatter();
	const [showAddPayment, setShowAddPayment] = createSignal(false);
	const [showAuth, setShowAuth] = createSignal(false);
	const authDialogId = createUniqueId();
	const [showAddCard, setShowAddCard] = createSignal(false);

	let form: HTMLFormElement;

	const [store, setStore] = createStore({
		rateId: searchParams.rateId || null,
		players: searchParams.players
			? isNaN(parseInt(searchParams.players, 10))
				? null
				: parseInt(searchParams.players, 10)
			: null,
	});

	const [cardId, setCardId] = createSignal<string>();
	const reserveAction = useMutation(reserve);
	const triggerFormAction = useAction(reserveAction);

	const user = useUser();
	const ccInfo = createAsync(() => (!user() ? Promise.resolve(undefined) : getCreditCards({})));

	const receipt = createAsync(
		() =>
			getPaymentInfo({
				teeTimeId: params.teeTimeId,
				rateId: store.rateId,
				players: store.players,
				includeAccess: !!searchParams.includeAccess,
			}),
		{ deferStream: true },
	);

	return (
		<>
			<Switch>
				<Match when={!receipt.latest?.info.teeTime}>
					<HttpStatusCode code={404} />
					<div class="flex flex-col gap-8">
						<p>The requested tee time is no longer available.</p>
						<div class="flex flex-row">
							<Button as={Link} href={`/course/${facility()?.facility.slug}/reserve-tee-time`} class="shrink grow-0">
								See available tee times
							</Button>
						</div>
					</div>
				</Match>

				<Match when>
					<Show when={receipt.latest?.info}>
						{(data) => (
							<Form document={reserveMutation} action={reserveAction} method="post" ref={form!}>
								<input
									type="hidden"
									name="source"
									value={utm().campaign === 'course-booking-link' ? 'course-site' : utm().source}
								/>
								<Suspense>
									<input type="hidden" name="reserveId" value={data().reserveId} />
									<Show when={searchParams.triggerId}>
										<input type="hidden" name="triggerId" value={searchParams.triggerId} />
									</Show>
								</Suspense>
								<Grid>
									<GridMain class="flex flex-col gap-4">
										<h2 class="text-xl font-semibold">Reservation details</h2>

										<ul class="flex flex-col gap-3">
											<li class="flex items-center gap-2">
												<IconCalendar class="size-6 text-brand" />
												<span>{formatDateTime(dayTimeToDate(data().rate.dayTime as CalendarDayTime))}</span>
											</li>
											<li class="flex items-center gap-2">
												<IconMapPin class="size-6 text-brand" />
												<span>{data().course.name}</span>
											</li>
											<Suspense>
												<li class="flex items-center gap-2">
													<IconFlag class="size-6 text-brand" />
													<span>{holesFormatter(numberFormatter(), data().holes)} holes</span>
												</li>
												<Show when={data().rate.cartIncluded}>
													<li class="flex items-center gap-2">
														<IconGolfCart class="size-6 text-brand" />
														<span>Cart included</span>
													</li>
												</Show>
											</Suspense>
										</ul>

										<Show when={data()?.courseNotes}>
											{(notes) => (
												<div class="mt-8 flex flex-col gap-2 rounded bg-neutral-100 p-4">
													<h3 class="text-sm font-semibold">Notes from the course:</h3>
													<p class="text-sm">{notes()}</p>
												</div>
											)}
										</Show>

										<hr class="my-4 border-neutral-500 md:my-6" />

										<RadioGroup name="__players" onSelect={(index) => setStore('players', parseInt(index, 10) + 1)}>
											<Label class="text-xl font-semibold">Players</Label>
											<RadioBar>
												<For each={new Array(4).fill(0)}>
													{(info, index) => (
														<RadioBarButton
															value={index()}
															checked={
																!store.players ? data().rate.minPlayers === index() + 1 : store.players === index() + 1
															}
															disabled={data().rate.minPlayers > index() + 1 || data().rate.maxPlayers <= index()}
														>
															<Label>{index() + 1}</Label>
														</RadioBarButton>
													)}
												</For>
											</RadioBar>
											<FieldDescription>
												<Switch>
													<Match when={data().rate.minPlayers > 1}>
														<p>
															The {data().rate.name} rate requires a minimum of {data().rate.minPlayers} players to book
															this tee time.
														</p>
													</Match>
													<Match when>
														<p>
															There {data().rate.maxPlayers > 1 ? 'are' : 'is'} {data().rate.maxPlayers} spot
															{data().rate.maxPlayers > 1 ? 's' : ''} available for this tee time.
														</p>
													</Match>
												</Switch>
											</FieldDescription>
										</RadioGroup>

										<Suspense>
											<Show
												when={
													[
														...data().availableRates.sort((a, b) => a.price.value - b.price.value),
														...(data().troonSubscriptionRate ? [data().troonSubscriptionRate] : []),
													] as Array<CourseTeeTimeRate>
												}
											>
												{(rates) => (
													<>
														<hr class="my-4 border-neutral-500 md:my-6" />
														<h2 class="text-xl font-semibold">Choose your rate</h2>
														<RadioGroup name="__rateId">
															<Label class="sr-only">Choose your rate</Label>
															<div class="flex flex-col gap-3">
																<For each={rates()}>
																	{(rate) => (
																		<Switch>
																			<Match when={rate.isTroonCardRate && rate.isAvailableToUser !== true}>
																				<div class="flex flex-col gap-2">
																					<AccessCardUpsellRate
																						rate={rate}
																						isSelected={store.rateId === rate.id || data().rate.id === rate.id}
																					/>
																					<Show when={!user() && data().troonSubscriptionRate}>
																						{(product) => (
																							<p>
																								Already have {product().name}?{' '}
																								<TextLink
																									aria-haspopup="dialog"
																									aria-expanded={showAuth()}
																									aria-controls={showAuth() ? `${authDialogId}-content` : undefined}
																									onClick={(e) => {
																										e.preventDefault();
																										setShowAuth(true);
																									}}
																									href="/auth"
																								>
																									Log in
																								</TextLink>
																							</p>
																						)}
																					</Show>
																				</div>
																			</Match>
																			{/* IMPORTANT: this prop is NOT set on public rates. check strictly equal to false */}
																			<Match when={rate.isAvailableToUser !== false}>
																				<div
																					// eslint-disable-next-line tailwindcss/no-arbitrary-value
																					class="cursor-pointer rounded border border-neutral p-4 has-[:checked]:border-brand has-[:checked]:bg-brand-100 md:p-6"
																					onClick={() => {
																						setStore(
																							produce((s) => {
																								s.players = s.players
																									? Math.max(Math.min(rate.maxPlayers, s.players), rate.minPlayers)
																									: null;
																								s.rateId = rate.id;
																							}),
																						);
																					}}
																				>
																					<Radio
																						value={rate.id ?? 'asdf'}
																						checked={store.rateId === rate.id || data().rate.id === rate.id}
																					>
																						<Label class="flex flex-col gap-1 ps-2">
																							<span class="font-semibold">{rate.name}</span>
																							<span class="text-sm text-neutral-800">
																								{rate.price.displayValue} per player
																							</span>
																						</Label>
																					</Radio>
																				</div>
																			</Match>
																		</Switch>
																	)}
																</For>
															</div>
														</RadioGroup>
													</>
												)}
											</Show>
										</Suspense>

										<Suspense>
											<Show when={data()?.course.requiresCCAtBooking && user()}>
												<hr class="my-4 border-neutral-500 md:my-6" />
												<h2 class="text-xl font-semibold">Payment method</h2>
												<p class="text-sm text-neutral-700">
													This course requires a credit card to book a tee time. You will only be charged if you no show
													or cancel beyond the cancellation policy.
												</p>
												<RadioGroup name="creditCardId" onSelect={(value) => setCardId(value)}>
													<Label class="sr-only">Credit card</Label>
													<Show when={(ccInfo()?.creditCards ?? []).length}>
														<div class="flex flex-col gap-2 rounded py-2">
															<For each={ccInfo()?.creditCards ?? []}>
																{(card) => {
																	const CardIcon = cardBrandToComponent[card.brand];
																	return (
																		<div
																			// eslint-disable-next-line tailwindcss/no-arbitrary-value
																			class="cursor-pointer rounded border border-neutral p-4 has-[:checked]:border-brand has-[:checked]:bg-brand-100 md:p-6"
																			onClick={() => setCardId(card.id)}
																		>
																			<Radio value={card.id} checked={cardId() === card.id}>
																				<Label class="flex flex-row items-center gap-2">
																					<div class="w-10">
																						<CardIcon />
																					</div>
																					<div class="grow">
																						{cardBrandToString[card.brand]} ending in {card.lastFour}
																					</div>
																				</Label>
																			</Radio>
																		</div>
																	);
																}}
															</For>
														</div>
													</Show>
												</RadioGroup>
												<div class="self-start">
													<Dialog
														key="reserve-add-payment-method"
														open={showAddPayment()}
														onOpenChange={setShowAddPayment}
													>
														<DialogTrigger appearance="transparent">+ Add payment method</DialogTrigger>
														<DialogContent header="Add payment method" headerLevel="h2">
															<AddCard
																onSuccess={(cardId) => {
																	setCardId(cardId);
																	setShowAddPayment(false);
																}}
															/>
														</DialogContent>
													</Dialog>
												</div>
											</Show>
										</Suspense>
									</GridMain>

									<GridSidebar>
										<div class="sticky top-24 flex flex-col gap-y-2 rounded border border-neutral-500 p-4 md:p-6">
											<PaymentInfo
												receipt={receipt()?.info.paymentInfo.receipt}
												rewardPointsEarned={receipt()?.info.paymentInfo.rewardPointsEarned}
												supportsTroonRewards={receipt()?.info.course.supportsTroonRewards}
											/>

											<Suspense>
												<Show when={receipt.latest}>
													<h3 class="text-lg font-semibold">
														<IconSquareWarning class="text-brand" /> Cancellation policy
													</h3>
													<p class="text-sm text-neutral-700">{data().cancellationInfo.displayMessage}</p>
												</Show>

												<Errors />

												<Switch>
													<Match when={!user()}>
														<>
															<Button
																type="button"
																aria-haspopup="dialog"
																aria-expanded={showAuth()}
																aria-controls={showAuth() ? `${authDialogId}-content` : undefined}
																onClick={(e) => {
																	e.preventDefault();
																	setShowAuth(true);
																}}
															>
																Log in to book
															</Button>

															<Dialog
																key="reserve-login-signup"
																open={showAuth()}
																onOpenChange={setShowAuth}
																id={authDialogId}
															>
																<DialogContent autoWidth noPadding noClose floatingClose>
																	<AuthFlow
																		onComplete={() => setShowAuth(false)}
																		headings={{ '/auth': 'Log in or sign up to continue booking' }}
																	/>
																</DialogContent>
															</Dialog>
														</>
													</Match>

													<Match when={data().course.requiresCCAtBooking && !cardId()}>
														<Dialog key="reserve-add-payment-method" open={showAddCard()} onOpenChange={setShowAddCard}>
															<DialogTrigger type="button" disabled={!receipt()}>
																Book now
															</DialogTrigger>
															<DialogContent header="Add payment method" headerLevel="h2">
																<AddCardDialog
																	cards={ccInfo()?.creditCards ?? []}
																	onSuccess={(creditCardId) => {
																		const data = new FormData(form);
																		setCardId(creditCardId);
																		data.append('creditCardId', creditCardId);
																		setShowAddCard(false);
																		triggerFormAction(data);
																	}}
																/>
															</DialogContent>
														</Dialog>
													</Match>

													<Match
														when={!data().course.requiresCCAtBooking || (data().course.requiresCCAtBooking && cardId())}
													>
														<Button disabled={!receipt()} type="submit">
															Book now
														</Button>
													</Match>
												</Switch>
											</Suspense>
										</div>
									</GridSidebar>
								</Grid>
							</Form>
						)}
					</Show>
				</Match>
			</Switch>
		</>
	);
}

function AddCardDialog(props: ComponentProps<typeof AddCard> & { cards: Array<CreditCard> }) {
	const [cardId, setCardId] = createSignal<string>();
	const [showForm, setShowForm] = createSignal(!props.cards.length);

	return (
		<div class="flex flex-col gap-y-4">
			<p class="text-sm">
				This course requires a credit card to book a tee time. You will only be charged if you no show or cancel beyond
				the cancellation policy.
			</p>

			<Switch>
				<Match when={!showForm()}>
					<RadioGroup name="creditCardId" onSelect={(value) => setCardId(value)}>
						<Label class="sr-only">Credit card</Label>
						<Show when={props.cards.length}>
							<div class="flex flex-col gap-2 rounded bg-neutral-100 px-4 py-2">
								<For each={props.cards}>
									{(card) => {
										const CardIcon = cardBrandToComponent[card.brand];
										return (
											<Radio value={card.id} checked={cardId() === card.id}>
												<Label class="flex flex-row items-center gap-2">
													<div class="w-10">
														<CardIcon />
													</div>
													<div class="grow">
														{cardBrandToString[card.brand]} ending in {card.lastFour}
													</div>
												</Label>
											</Radio>
										);
									}}
								</For>
							</div>
						</Show>
					</RadioGroup>
					<div class="self-start">
						<Button appearance="transparent" onClick={() => setShowForm(true)}>
							+ Add payment method
						</Button>
					</div>
					<Button
						onClick={() => {
							props.onSuccess!(cardId()!);
						}}
						disabled={!cardId()}
					>
						Book tee time
					</Button>
				</Match>
				<Match when={showForm()}>
					<AddCard {...props} buttonText={'Add card & book tee time'} />
				</Match>
			</Switch>
		</div>
	);
}

const reserveMutation = gql(`
mutation reserveTeeTime(
	$reserveId: String!
	$creditCardId: String
	$triggerId: String
	$source: String
) {
	reserveTeeTime(
		reserveId: $reserveId
		creditCardId: $creditCardId
		teeTimeAlertTriggerId: $triggerId
		source: $source
	) {
		id
		teeTimeId
		courseId
		state
		playerCount,
		holeCount
		includesCart
		dayTime {
			...DayTime
		}
	}
}
`);

const reserve = mutationAction(reserveMutation, {
	revalidates: ['home', 'allReservations'],
	redirect: (data) => `/reservation/${data?.reserveTeeTime.id}`,
	redirectOptions: { state: { confirmed: true } },
	toast: 'Your tee time has been confirmed!',
	track: {
		event: 'reserveTeeTime',
		transform: (data, res) => ({
			courseId: data.get('courseId') as string,
			...(res?.reserveTeeTime.dayTime as CalendarDayTime)?.day,
		}),
	},
});

const paymentInfoQuery = gql(`
query teeTimePaymentInfo(
	$teeTimeId: String!
	$rateId: String
	$players: Int
	$includeAccess: Boolean
) {
	info: courseTeeTimeReservationInfo(
		teeTimeId: $teeTimeId
		rateId: $rateId
		players: $players
		troonCardSubscriptionRates: $includeAccess
	) {
		reserveId
		teeTime {
			...TeeTime
		}
		courseNotes
		availableRates {
			id
			name
			dayTime {
				...DayTime
			}
			holesOption
			cartIncluded
			practiceBallsIncluded
			minPlayers
			maxPlayers
			price {
				...Currency
			}
		}
		rate {
			id
			name
			dayTime {
				...DayTime
			}
			holesOption
			cartIncluded
			practiceBallsIncluded
			minPlayers
			maxPlayers
			price {
				...Currency
			}
		}
		troonSubscriptionRate {
			id
			name
			dayTime {
				...DayTime
			}
			isAvailableToUser
			isPrePaid
			isTroonCardRate
			isLocalCardRate
			holesOption
			cartIncluded
			practiceBallsIncluded
			minPlayers
			maxPlayers
			price {
				...Currency
			}
		}
		paymentInfo {
			rewardPointsEarned
			receipt {
				...PaymentInfo
				total {
					...Currency
				}
				items {
					label
					amount {
						...Currency
					}
					itemType
				}
			}
		}
		cancellationInfo {
			displayMessage
		}
		course {
			id
			name
			requiresCCAtBooking
			supportsTroonRewards
		}
		players
		holes
	}
}
`);

const getPaymentInfo = cachedQuery(paymentInfoQuery);

const cardQuery = gql(`
query paymentMethods {
	creditCards {
		id
		lastFour
		brand
	}
}`);

const getCreditCards = cachedQuery(cardQuery);
